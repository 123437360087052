import React, { useState } from "react";
import HeaderAlt from "../header/HeaderAlt";
import Footer from "../footer/Footer";
import client from "../../services/client";
import Constants from "../utils/Constants";
import ReactGA from 'react-ga4';

// Add Analytics tracking
ReactGA.send({ hitType: "pageview", page: "/blog", title: "Blog" });

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      skip: 0,
    };
  }

  isModalOpen = (id) => {
    const currentItem = this.state.posts.filter(item => item.id === id);
    return currentItem.length !== 0 ? currentItem[0].isOpen : false;
  }

  toggleModal = (id) => {    
    let currentItem = this.state.posts.filter(item => item.id === id);
    if (currentItem.length !== 0) {
      let posts = this.state.posts;
      posts = posts.map(item => {
        if (item.id === id) {
          item.isOpen = !item.isOpen;
        }
        return item;
      });

      this.setState({ posts: posts });
    }
  }

  fetchPosts = () => {
    client.getEntries({
      content_type: Constants.BlogEntryContentType,
      order: '-sys.createdAt',
      limit: 10,
      skip: this.state.skip
    })
    .then(response => {
      const blogEntries = response.items.map(item => ({
        id: item.sys.id,
        author: {
          name: item.fields.author.fields.name,
          thumbnail: item.fields.author.fields.thumbnail.fields.file.url
        },
        date: new Date(item.sys.createdAt).toDateString(),
        title: item.fields.title,
        slug: item.fields.slug,
        excerpt: item.fields.excerpt,
        coverImage: item.fields.coverImage.fields.file.url        
      }));
      
      this.setState({ posts: blogEntries });           
    })
    .catch(console.error);
  }

  handleOlderPosts = () => {
    this.setState({ skip: this.state.skip + 10 }, () => {
      this.fetchPosts();
    });   
  }

  componentDidMount() {
    this.fetchPosts();
  }  

  render() {
    const { posts } = this.state;

    return (
      <div className="home-two">
        <HeaderAlt />        
        <div className="shane_tm_section" id="blog">
          <div className="shane_tm_blog">
            <div className="container">
              <div className="shane_tm_title">
                <span>Blog</span>
                <h3>&Uacute;ltimos posts</h3>
              </div>              
              <div className="blog_list">                
                <ul>
                  { posts.length === 0 && (
                      <div>No existen posts para mostrar</div>
                    )
                  }
                  {posts.length > 0 &&  posts.map((post, index) => (
                    <li data-aos="fade-right" data-aos-duration="1200" key={index}>
                      <div className="list_inner">
                        <a href={ `/blog/${post.slug}` }>
                          <div className="image">
                            <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{ backgroundImage: `url(${post.coverImage})` }}
                            ></div>
                          </div>
                        </a>
                        <div className="details">
                          <a href={ `/blog/${post.slug}` }>
                            <h3 className="title">
                              { post.title }
                            </h3>
                          </a>
                          <div className="excerpt">
                            <p>{ post.excerpt }</p>
                          </div>
                          <p className="date">
                            Por { post.author.name } <span>{ post.date }</span>                        
                          </p>
                        </div>                        
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="shane_tm_button aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                <a href="#" onClick={() => this.handleOlderPosts()}>
                    Anteriores
                </a>
              </div>     
            </div>
          </div>     
        </div>
        <Footer />                  
      </div>
    );
  }
}

export default Blog;