import React from "react";
import Social from "../SocialTwo";
import { InfinitySpin } from  'react-loader-spinner';

class Slider extends React.Component {  
    render() {            
      const { homeEntry } = this.props;
    
      if (!homeEntry) {
        return (
          <div className="loading">
              <InfinitySpin width='200' color="#9400D3" />
          </div>
        );  
      }

      return (
        <div className="slider-two">
          <div className="shane_tm_hero" id="home" data-style="three">
            <div className="background">
              <div
                className="image"
                style={{ backgroundImage: `url('https:${homeEntry.fields.header.fields.file.url}')` }}
              ></div>
            </div>
            {/* End .background */}
    
            <div className="container">
              <div className="content">
                <div className="name_wrap">
                  <h3 dangerouslySetInnerHTML={{ __html: homeEntry.fields.headerTitle }} />                                            
                </div>
                {/* End title */}
    
                <div className="job_wrap">
                  <span className="job">                  
                    { homeEntry.fields.headerSubtitle }
                    <span className="overlay_effect"></span>
                  </span>
                </div>
                {/* End designation */}
    
                <div className="job_wrap sociallo">
                  <span className="job">
                    <Social networks={homeEntry.fields.socialNetworks} />
                    <span className="overlay_effect"></span>
                  </span>
                </div>
              </div>
              {/* End content */}
    
              <div className="shane_tm_down loaded">
                <div className="line_wrapper">
                  <div className="line"></div>
                </div>
              </div>
            </div>
            {/* End .container */}
          </div>
        </div>
      );
    }
}

export default Slider;
