import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery } from "react-photoswipe-gallery";
import { FacebookEmbed, InstagramEmbed, TikTokEmbed } from 'react-social-media-embed';
import client from "../../services/client";
import Constants from "../utils/Constants";
import HeaderAlt from "../header/HeaderAlt";
import Footer from "../footer/Footer";
import { InfinitySpin } from  'react-loader-spinner';
import ReactGA from 'react-ga4';

// Add Analytics tracking
ReactGA.send({ hitType: "pageview", page: "/social", title: "Social Network" });

class SocialNetwork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      socialNetworks: [],
    };
  } 

  componentDidMount() {    
    client.getEntries({
      content_type: Constants.SocialNetworkContentType,
      order: 'sys.createdAt'
    })
    .then(response => {      
      const networks = response.items.map(item => ({
        type: item.fields.applicationName,
        posts: item.fields.posts
      }));

      this.setState({ socialNetworks: networks });           
    })
    .catch(console.error);
  }

  render() {
    const { socialNetworks } = this.state;

    if (!socialNetworks) {
      return (
        <div className="loading">
            <InfinitySpin width='200' color="#9400D3" />
        </div>
      );  
    }  

    return (
      <div className="shane_tm_section" id="portfolio">
        <HeaderAlt />
        <div className="shane_tm_portfolio">
          <div className="container">
            <div className="positon-relative">
              <div className="shane_tm_title">
                <div className="title_flex">
                  <div className="left">
                    <span>Mis Redes</span>
                    <h3>Redes Sociales</h3>
                  </div>
                </div>
              </div>
              {/* End shane_tm_title */}

              <div className="portfolio_filter">
                <Tabs>
                  <TabList>
                    {socialNetworks.map((val, i) => (
                      <Tab key={val.type}>{val.type}</Tab>
                    ))}
                  </TabList>
                  {/* End tablist */}

                  <div className="portfolio_list has-effect">
                    <Gallery>
                      {socialNetworks.map((network, i) => (
                        <TabPanel key={network.type}>
                          <ul className="gallery_zoom">
                            {network.posts.map((val, i) => (
                              <li
                                key={i}
                                data-aos="fade-right"
                                data-aos-duration="1200"
                                data-aos-delay={val.delayAnimation}
                              >
                                <div className="inner">
                                  <div className="entry shane_tm_portfolio_animation_wrap">
                                    <div style={{ display: 'flex', justifyContent: 'center'}}>
                                      {
                                        network.type === "facebook" ?
                                        <FacebookEmbed url={val.url + "&omitscript=true"} width={350} /> :
                                        (
                                          network.type === "instagram" ?
                                          <InstagramEmbed url={val.url} width={350} scriptLoadDisabled={true} /> :
                                          <TikTokEmbed url={val.url} width={500} height={800} />
                                        )
                                      }
                                    </div>
                                  </div>                                  
                                </div>
                              </li>
                            ))}
                          </ul>
                        </TabPanel>
                      ))}
                    </Gallery>
                    {/* End tabpanel */}
                  </div>
                  {/* End list wrapper */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
}

export default SocialNetwork;
