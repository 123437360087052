import React, { useState } from "react";
import client from "../../services/client";
import Constants from "../utils/Constants";

class BlogSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    client.getEntries({
      content_type: Constants.BlogEntryContentType,
      order: '-sys.createdAt',
      limit: 3
    })
    .then(response => {            
      const blogEntries = response.items.map(item => ({
        id: item.sys.id,
        author: {
          name: item.fields.author.fields.name,
          thumbnail: item.fields.author.fields.thumbnail.fields.file.url
        },
        date: new Date(item.sys.createdAt).toDateString(),
        title: item.fields.title,
        slug: item.fields.slug,
        excerpt: item.fields.excerpt,
        coverImage: item.fields.coverImage.fields.file.url,
      }));
     
      this.setState({ posts: blogEntries });           
    })
    .catch(console.error);
  }

  render() {
    const { posts } = this.state;

    if (posts.length === 0) {
      return (
        <div className="shane_tm_section" id="news">
          <div className="shane_tm_blog">
            <div className="container">
              <div className="shane_tm_title">
                <span>Blog</span>
                <h3>&Uacute;ltimos posts</h3>
              </div>            
              {/* End shane_tm_title */}
              <div className="blog_list"></div>
                <div>No existen noticias para mostrar</div>
              </div>
            </div>
          </div>
      )
    }

    return (
      <div className="shane_tm_section" id="blog">
        <div className="shane_tm_blog">
          <div className="container">
            <div className="shane_tm_title">
              <span>Blog</span>
              <h3>&Uacute;ltimos posts</h3>
            </div>
            {/* End shane_tm_title */}
            <div className="blog_list">
              <ul>
                {posts.map((post, index) => (
                  <li data-aos="fade-right" data-aos-duration="1200" key={index}>
                    <div className="list_inner">
                      <a href={`/blog/${post.slug}`}>
                        <div className="image" onClick={() => this.toggleModal(post.id)}>
                          <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                            <div
                              className="main"
                              style={{ backgroundImage: `url(${post.coverImage})` }}
                            ></div>
                        </div>
                      </a>
                      <div className="details">
                        <a href={`/blog/${post.slug}`}>
                          <h3 className="title" onClick={() => this.toggleModal(post.id)}>
                            { post.title }
                          </h3>
                        </a>
                        <p className="date">
                          Por <a href="#">{ post.author.name }</a> <span>{ post.date }</span>                        
                        </p>
                    </div>
                  </div>
                </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogSummary;