import React from 'react';
import client from "../../services/client";
import Constants from "../utils/Constants";
import HeaderAlt from '../header/HeaderAlt';
import Footer from '../footer/Footer';
import { Dna, InfinitySpin } from  'react-loader-spinner';
import ReactGA from 'react-ga4';

// Add Analytics tracking
ReactGA.send({ hitType: "pageview", page: "/courses", title: "Courses" });

class Courses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          homeEntry: null,
        };
    }
    
    componentDidMount() {
        client.getEntry(Constants.AboutEntryId).then((response) => {
          this.setState({ homeEntry: response });
        });
    }
    
    render() {
        const style = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f0f0',
            color: '#333',
            fontSize: '2em',
            fontFamily: '"Helvetica, Arial, sans-serif"',
        };

        const { homeEntry } = this.state;

        if (!homeEntry) {
            return (
                <div className="loading">
                    <InfinitySpin width='200' color="#9400D3" />
                </div>
            );      
        }

        return (
            <div className="home-two">
                <HeaderAlt logo={homeEntry.fields.logo} networks={homeEntry.fields.socialNetworks} />
                {/* End Header Section */}

                <div className="shane_tm_section" id="about">
                    <div className="shane_tm_about">
                        <div className="container">
                            <div className="loading">
                                <Dna visible={true}
                                height="200"
                                width="200"
                                ariaLabel="dna-loading"
                                wrapperStyle={{}}
                                wrapperClass="dna-wrapper"                        
                                />
                            </div>

                            <div><h3>Pr&oacute;ximamente...</h3></div>
                        </div>
                    </div>
                </div>
                {/* End About Section */}

                <Footer />
            </div>
        );
    }
};

export default Courses;
