import React from "react";
import client from "../services/client";
import Header from "../components/header/Header";
import Slider from "../components/slider/SliderTwo";
import AboutSummary from "../components/about/AboutSummary";
import BlogSummary from "../components/blog/BlogSummary";
import NewsSummary from "../components/news/NewsSummary";
import CallToAction from "../components/calltoactions/CallToActionTwo";
import Footer from "../components/footer/Footer";
import Constants from "../components/utils/Constants";
import SocialNetworkSummary from "../components/portfolio/SocialNetworkSummary";
import { InfinitySpin } from  'react-loader-spinner';
import ReactGA from 'react-ga4';

// Add Analytics tracking
ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      homeEntry: null,
    };
  }  

  componentDidMount() {
    client.getEntry(Constants.HomeEntryId).then(response => {          
        this.setState({ homeEntry: response })      
    });
  }  

  render() {
    const { homeEntry } = this.state;

    if (!homeEntry) {
      return (
      <div className="loading">
          <InfinitySpin width='200' color="#9400D3" />
      </div>
      );        
  }

    return (
        <div className="home-two">
          <Header logo={homeEntry.fields.logo} networks={homeEntry.fields.socialNetworks} />
          {/* End Header Section */}

          <Slider homeEntry={homeEntry} />
          {/* End Slider Section */}

          <AboutSummary />
          {/* End About Section */}

          <SocialNetworkSummary />
          {/* End SocialNetwork Section */}

          <NewsSummary />
          {/* End Blog Section */}

          <BlogSummary />
          {/* End shane_tm_partners */}

          <CallToAction />
          {/* End CallToAction */}

          <Footer />
          {/* End Footer Section */}
        </div>
      )
  }
}

export default Home;
