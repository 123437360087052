import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import client from "../../services/client";
import HeaderAlt from "../header/HeaderAlt";
import Footer from "../footer/Footer";
import Constants from "../utils/Constants";
import styles from "../../assets/scss/news.module.css";
import { InfinitySpin } from  'react-loader-spinner'
import ReactGA from 'react-ga4';

const NewDetail = () => {
    const { slug } = useParams();
    const [detail, setDetail] = useState(null);    

    const addAnalytics = (slug, title) => {
        // Add Analytics tracking
        ReactGA.send({ hitType: "pageview", page: `/news/${slug}`, title: title });
    }

    const fetchNewDetail = (newSlug) => {        
        client.getEntries({
            content_type: Constants.NewsEntryContentType,
            'fields.slug': newSlug,
            order: 'sys.createdAt',
            limit: 1
        })
        .then(response => {
            const detail = {
                author: {
                    name: response.items[0].fields.author.fields.name,
                    thumbnail: response.items[0].fields.author.fields.thumbnail.fields.file.url
                },
                date: new Date(response.items[0].sys.createdAt).toDateString(),
                title: response.items[0].fields.title,
                slug: response.items[0].fields.slug,
                excerpt: response.items[0].fields.excerpt,
                coverImage: response.items[0].fields.coverImage.fields.file.url          
            };
            
            // Add Analytics tracking
            addAnalytics(detail.slug, detail.title);            
            const renderedHtml = documentToHtmlString(response.items[0].fields.content);

            return {detail, renderedHtml};
        })
        .then(({detail, renderedHtml}) => {
            detail.content = renderedHtml;
            setDetail(detail);   
        })
        .catch(console.error);
    }

    useEffect(() => {
        fetchNewDetail(slug);
    }, [slug]);

    if (!detail) {
        return (
        <div className="loading">
            <InfinitySpin width='200' color="#9400D3" />
        </div>
        );        
    }

    return (
        <div className="home-two">
            <HeaderAlt />        
            <div className="shane_tm_section" id="news">
                <div className="shane_tm_news">
                    <div className="container">   
                        <div className={styles.article}>
                            <h3 className={styles.title}>{detail.title}</h3>
                            <div className={styles.coverImage} 
                            style={{ 'backgroundImage': `url('${detail.coverImage}')` }}>
                            </div>                        
                            <div className={styles.author}>
                                <img src={detail.author.thumbnail} alt="Author" className={styles.authorThumbnail} />
                                <p className={styles.authorName}>
                                    Por { detail.author.name }{" "}
                                </p>    
                                &nbsp; | &nbsp;                        
                                <p className={styles.authorDate}>                                
                                    <span>{ detail.date }</span>
                                </p>                        
                            </div>
                            <hr className={styles.hr} />
                            <div className={styles.content}>
                                <div dangerouslySetInnerHTML={{__html: detail.content}} />
                            </div>
                        </div>      
                    </div>
                </div>
            </div>
            <Footer />
        </div>            
    )    
}

export default NewDetail;