import React from "react";
import { InfinitySpin } from  'react-loader-spinner';

const Social = (props) => {
  const { networks } = props;  

  if (!networks) {
    return (
      <div className="loading">
          <InfinitySpin width='200' color="#9400D3" />
      </div>
    );  
  }

  return (
    <>
      <ul className="social social-default position-relative">
        {networks.map((val, i) => (
          <li key={i}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/img/svg/social/${val.iconName}.svg`}
                alt="social"
              ></img>
            </a>
          </li>
        ))}
      </ul>
      {/* END social */}
    </>
  );
};

export default Social;
