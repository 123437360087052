import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import Social from "../SocialTwo";
import { NavLink } from "react-router-dom";

const Header = (props) => {
  const { logo, networks } = props;  
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className="shane_tm_topbar">
        <div className={navbar ? "topbar_inner opened" : "topbar_inner"}>
          <div className="logo">
            <NavLink to="/">
              { logo ? 
               <img src={'https:' + logo.fields.file.url} alt="Latir Espiritual" style={{width:'200px'}} /> :
               <img src="/img/logo/new/dark.png" alt="partners brand" />
              }
            </NavLink>
          </div>
          {/* End logo */}
          <div className="menu">
            <Scrollspy
              className="anchor_nav"
              items={["home", "about", "portfolio", "news", "blog", "cursos"]}
              currentClassName="current"
              offset={-200}
            >
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">Sobre M&iacute;</a>
              </li>
              <li>
                <a href="/social">Mis Redes</a>
              </li>
              <li>
                <a href="/news">Noticias</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a href="/courses">Cursos</a>
              </li>
              <li>
                <a 
                  className="white-fill-bg btn-outline btn_sm" href="#" onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                  }}
                >
                  Contacto
                </a>
              </li>
            </Scrollspy>
          </div>
          {/* End menu */}
        </div>
      </div>
      {/* End shane_tm_topbar */}

      {/* Start shane mobile menu */}
      <div className="shane_tm_mobile_menu">
        <div className="topbar_inner">
          <div className="container bigger">
            <div className="topbar_in">
              <div className="logo">
                <NavLink to="/">
                  { logo ? 
                  <img src={'https:' + logo.fields.file.url} alt="Latir Espiritual" style={{width:'200px'}} /> :
                  <img src="/img/logo/new/dark.png" alt="partners brand" />
                  }
                </NavLink>
              </div>
              {/* End logo */}
              <div className="my_trigger" onClick={handleClick}>
                <div
                  className={
                    click
                      ? "hamburger hamburger--collapse-r is-active"
                      : "hamburger"
                  }
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
                {/* End hamburger menu */}
              </div>
            </div>
          </div>
        </div>
        <div className={click ? "dropdown active" : "dropdown"}>
          <div className="container">
            <span className="close_menu" onClick={handleClick}>
              cerrar
            </span>
            <div className="dropdown_inner">
              <ul className="anchor_nav">
                <li className="current">
                  <a href="/" onClick={handleClick}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/about" onClick={handleClick}>
                    Sobre M&iacute;
                  </a>
                </li>
                <li>
                  <a href="/social" onClick={handleClick}>
                    Mis Redes
                  </a>
                </li>
                <li>
                  <a href="/news" onClick={handleClick}>
                    Noticias
                  </a>
                </li>
                <li>
                  <a href="/blog" onClick={handleClick}>
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/courses" onClick={handleClick}>
                    Cursos
                  </a>
                </li>
                <li>
                  <a
                    className="white-fill-bg btn-outline btn_sm"
                    target="_blank"
                    rel="noreferrer"
                    href="#"
                  >
                    Contacto
                  </a>
                </li>
              </ul>
              <div className="social-menu">
                <Social networks={networks} />
              </div>
              {/* End social share */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
      {/* End shane mobile menu */}
    </>
  );
};

export default Header;
