import React, { useState } from "react";
import Modal from "react-modal";
import client from "../../services/client";
import Constants from "../utils/Constants";
import NewDetail from "./NewDetail";

Modal.setAppElement("#root");

class NewsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: []
    };
  }

  componentDidMount() {
    client.getEntries({
      content_type: Constants.NewsEntryContentType,
      order: '-sys.createdAt',
      limit: 3
    })
    .then(response => {            
      const newsEntries = response.items.map(item => ({
        id: item.sys.id,
        author: {
          name: item.fields.author.fields.name,
          thumbnail: item.fields.author.fields.thumbnail.fields.file.url
        },
        date: new Date(item.sys.createdAt).toDateString(),
        title: item.fields.title,
        slug: item.fields.slug,
        excerpt: item.fields.excerpt,
        coverImage: item.fields.coverImage.fields.file.url
      }));

      this.setState({ news: newsEntries });           
    })
    .catch(console.error);
  }

  render() {
    const { news } = this.state;
    
    return (
      <div className="shane_tm_section" id="news">
        <div className="shane_tm_news">
          <div className="container">
            <div className="shane_tm_title">
              <span>Noticias</span>
              <h3>&Uacute;ltimas noticias</h3>
            </div>            
            {/* End shane_tm_title */}
            <div className="news_list">
              <ul>
              {news.length === 0 && (
                <div>No existen noticias para mostrar</div>
              )}
              {news.length > 0 && news.map(item => (
                  <li key={item.id} data-aos="fade-right" data-aos-duration="1200">
                    <div className="list_inner">
                      <a href={`/news/${item.slug}`}>
                        <div className="image" onClick={() => this.toggleModal(item.id)}>
                          <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                          <div
                            className="main"
                            style={{ backgroundImage: `url(${item.coverImage})` }} />
                        </div>
                      </a>

                      <div className="details">                        
                        <a href={`/news/${item.slug}`}>
                          <h3 className="title" onClick={() => this.toggleModal(item.id)}>
                            { item.title }
                          </h3>
                        </a>
                        <p className="date">
                          Por <a href="#">{ item.author.name }</a> <span>{ item.date }</span>
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default NewsSummary;
