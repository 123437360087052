import React from "react";
import client from '../../services/client';
import Constants from "../utils/Constants";
import { InfinitySpin } from  'react-loader-spinner';

class AboutSummary extends React.Component {
  state = {
    aboutEntry: null,
  };

  componentDidMount() {
    client.getEntry(Constants.AboutEntryId).then(response => {           
        this.setState({ aboutEntry: response })      
    });
  }

  render() {
    const { aboutEntry } = this.state;

    if (!aboutEntry) {
      return (
        <div className="loading">
            <InfinitySpin width='200' color="#9400D3" />
        </div>
      );  
    }

    return (
      <>
        <div className="shane_tm_section" id="about">
          <div className="shane_tm_about">
            <div className="container">
              <div className="about_inner">
                <div className="left">
                  <div
                    className="image"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <img src="/img/placeholders/1-1.jpg" alt="placeholder" />

                    <div
                      className="main"
                      style={{ backgroundImage: `url("https:${aboutEntry.fields.image.fields.file.url}")` }}
                    ></div>
                  </div>
                  {/* End image */}
                </div>
                {/* End left */}

                <div className="right">
                  <div
                    className="shane_tm_title"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <span>Sobre M&iacute;</span>
                    <h3>{ aboutEntry.fields.title }</h3>
                  </div>
                  <div
                    className="text"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                  >
                    <p>{ aboutEntry.fields.body }</p>
                  </div>
                </div>
                {/* End right */}
              </div>
            </div>
            {/* End container */}
          </div>
        </div>
      </>
    );
  }
}

export default AboutSummary;
