class Constants {
    static HomeEntryId = "4lla6pOR6LrRkAGW3RHGXj";
    static AboutEntryId = "6Fmhj708hpz9Q8EALM6Y15";
    static SocialNetworkContentType = "socialNetwork";
    static BlogEntryContentType = "blog";
    static NewsEntryContentType = "new";
    static RecaptchaSiteKey = `${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    static ContactEndpoint = `${process.env.REACT_APP_BACKEND_HOST}/sendemail`;
    static ServiceApiKey = `${process.env.REACT_APP_SERVICE_API_KEY}`;
}

export default Constants;