import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useState, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Constants from "./utils/Constants";
import { InfinitySpin } from  'react-loader-spinner';
import ReactGA from 'react-ga4';

const Contact = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }    
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');

  const addAnalytics = (isOk) => {
    // Add Analytics tracking
    ReactGA.event({
      category: "Contact",
      action: "form_submission",
      label: isOk ? "success" : "error",      
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }

  const onRecaptchaChange = useCallback((value) => { 
      if (value) {
        setRecaptchaValue(value);
      } else {        
        setRecaptchaValue(null);
        setError('recaptcha', {
          type: 'manual',
          message: 'Debe confirmar que no es un robot',
        });
      }
    },
    [setRecaptchaValue, setError]
  );

  const onSubmit = async (data, e) => {
    if (data.name === "" || data.email === "" || recaptchaValue === null) {
      alert("Por favor, complete todos los campos");
      return;
    }

    setIsLoading(true);

    const requestData = {
      name: data.name,
      email: data.email,
      recaptcha: recaptchaValue
    };

    try {
        const response = await axios.post(
          Constants.ContactEndpoint, 
          requestData, 
          { 
            headers: {
              'Access-Control-Allow-Origin': '*',
              'x-api-key': Constants.ServiceApiKey              
            }
          }
        );

        if (response.status === 200) {
          addAnalytics(true);
          setStatusMessage(`Gracias por registrarte. En breve te llegará un email con un link para descargar el libro.`);
        } else {
          addAnalytics(false);
          setStatusMessage(`Error al intentar registrar los datos. Por favor, intentelo nuevamente.`);
        }        
    } catch (error) {
        addAnalytics(false);
        setStatusMessage(`Error al intentar registrar los datos. Por favor, intentelo nuevamente.`);
        console.log(error)
    } finally {
      setIsLoading(false);
      e.target.reset();    
    }        
  };

  return (    
    <>
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="Nombre y Apellido"
              />
              {errors.name && errors.name.type === "required" && (
                <span>Nombre requerido</span>
              )}
            </li>
            {/* End first name field */}

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "Email requerido",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El valor ingresado no es un email valido",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="Email"
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>
            {/* End email name field */}

            <li>
              <ReCAPTCHA sitekey={Constants.RecaptchaSiteKey} onChange={onRecaptchaChange} />
              {errors.recaptcha && (
                <span role="alert">{errors.recaptcha.message}</span>
              )}
            </li>
            {/* End Recaptcha */}

          </ul>
        </div>

        <div className="tokyo_tm_button">
          {isLoading && 
            <div className="loading">
              <InfinitySpin width='200' color="#9400D3" />
            </div>
          }
          {!isLoading && 
            <button type="submit" className="white-fill-bg fill-black">
              Enviar
            </button>
          }          
        </div>
        {/* End tokyo_tm_button */}

        <div className="alert-wrapper-custom">
          <div id="alert-message" className="alert-message">{statusMessage}</div>
        </div>
        {/* End alert */}

      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
